// Do not edit. File generated automatically.
// Run "php artisan config:js" to refresh published configuration.
export default {
    "view": {
        "classPrefix": "mok-"
    },
    "vue_routers": {
        "backend": {
            "dashboard": "\/admin\/dashboard",
            "profile": "\/admin\/profile"
        }
    }
}